<template>
    <div
        class="px-3 pb-16"
    >
        <!-- 읽기 -->
        <PolicyRead
            v-if="$route.query.type=='read'"
            title="Privacy"
        />

        <!-- 쓰기 -->
        <PolicyWrite
            v-if="$route.query.type=='write' && $store.state.lafia_admin.is_logined"
            title="Privacy"
        />
    </div>
</template>
<script>
import PolicyRead from './components/Read'
import PolicyWrite from './components/Write'

export default {
    components: {
        PolicyRead,
        PolicyWrite
    }
}
</script>