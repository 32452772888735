<template>
    <v-sheet class="pa-2 ma-2">
        <!-- 에디터 -->
        <TipTapWriter 
            style="min-height:700px;"
            class="tiptap_style pa-2 mt-2"
            :options="options"
            v-if="options.content"
        />

        <!-- 버튼 -->
        <v-sheet class="d-flex justify-center mt-6">
            <v-btn
                dark
                depressed
                large
                class="mx-2 px-10 rounded-lg"
                color="grey"
                @click="cancel()"
            >
                취소하기
            </v-btn>
            <v-btn
                dark
                depressed
                large
                class="mx-2 px-10 rounded-lg"
                color="primary"
                @click="submit()"
            >
                수정하기
            </v-btn>
        </v-sheet>
    </v-sheet>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"

export default {
    props: ["title"],

    components: {
        TipTapWriter
    },

    data: () => ({
        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true,
        }
    }),

    mounted() {
        this.$http.post('/api/html/select/specific/title', {
            params: {
                title: this.title
            }
        }).then(res => {
            this.options.content=res.data[0].content;
        })
    },

    methods: {
        cancel(){
            if(confirm("지금 작성하고 계신 글이 저장되지 않을 수 있습니다.\n페이지를 이동하시겠습니까?")){
                this.$router.go(-1);
            }
        },

        submit(){
            this.$http.post('/api/html/update', {
                params: {
                    title: this.title,
                    content: this.options.content,
                }
            }).then((res) => {
                alert('수정이 처리되었습니다.')
                this.$router.push('/policy/'+this.title+'?type=read')
            })
        }
    }
}
</script>
